import './App.css';
import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
// import GMapPanel from './components/GMapPanel';
import MyMap from './components/MyMap';
import { cityData } from './data/provinceData';

function App() {
  const [selectedProvince, setSelectedProvince] = useState('');
  const [stores, setStores] = useState([]); 
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const [selectedCity, setSelectedCity] = useState('');
  const [mapCenter, setMapCenter] = useState(null);

  useEffect(() => {
    setInterval(() => {
      setShowTimeoutPopup(true);
  }, 10 * 60 * 1000); // 10 minutes
  }, []);
  
  const handleProvinceChange = (province) => {
    setSelectedProvince(province);
  };


  const handleRefresh = () => {
    window.location.reload();
  };
  const handleStoresChange = (stores) => {
    setStores(stores);
  };
  const handleCityChange = (city) => {
    setSelectedCity(city);
    const cityCoordinates = cityData[city.replace(/\s+/g, '')];
    if (cityCoordinates) {
      setMapCenter({ lat: cityCoordinates.lat, lng: cityCoordinates.lng });
    }
  };

  return (
    <div className="App">
      <Header />
      <div className="content">
        <Sidebar 
        onProvinceChange={handleProvinceChange} 
        onStoresChange={handleStoresChange} 
        onCityChange={handleCityChange}
        />
        <MyMap 
        selectedProvince={selectedProvince} 
        mapCenter={mapCenter}
        stores={stores} 
        selectedCity={selectedCity}
        />
      </div>
      {showTimeoutPopup && (
        <>
          <div className="timeout-overlay"></div>
          <div className="timeout-popup">
            <h2>Warning!</h2>
            <p>Your session has been inactive for 10 minutes. Please refresh the page.</p>
            <button onClick={handleRefresh}>Refresh</button>
          </div>
        </>
      )}
      <div className='footer'>
        <p>© 2024 <a href="https://www.carawaygrill.com/" style={{ color: '#e99c12', textDecoration: 'none' }}>Caraway Grill</a>. All rights reserved.</p>
        <p>Made with ❤️ by <a href="https://www.iorders.ca/" style={{ color: '#e99c12', textDecoration: 'none' ,fontSize:'15px'}}>iOrders</a> Version 2.2.1</p>
      </div>
    </div>
  );
}

export default App;